import React, { createContext, useEffect, useState } from 'react';
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink,from } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";

const clientId = window.location.hostname;
const platform = process.env.REACT_APP_PLATFORM
const link = new HttpLink({
  uri: process.env.REACT_APP_API_URI,
  credentials: 'include',
  headers: {
    ...(clientId && { 'X-ThaiRun-Client-ID': clientId }),
    ...(platform && {
      'X-RunX-Platform': platform,
      'x-thairun-platform': platform
    })
  }
});

const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    // if (
    //   ['PropertyQuestionItems', 'PropertyQuestionCondition','RegistrationTypeName','PropertyQuestionName','PropertyQuestionExtraMessage', 'PropertyQuestionInfoMessage'].includes(
    //     object.__typename
    //   )
    // ) {
    //   return null;
    // }
    if (['UserProfile'].includes(object.__typename)) {
      return null;
    }
    if (object.__typename === 'Registration') {
      console.log('o', object)
    }
    if (object._id) {
      return `${object.__typename}:${object._id}`
    }
    return null
    // return defaultDataIdFromObject(object);
  }
});

//////NO USER
const defaultAppolloClient = new ApolloClient({
  link,
  cache
});

export const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userIsLoading, setUserIsLoading] = useState(true);
  const [apolloClient, setApolloClient] = useState(defaultAppolloClient);

  const updateUser =  (data) => {
    if (!data) {
      setUser(null);
      setUserIsLoading(false);
      return;
    }
    setUser(data);
    setUserIsLoading(false);
  };

  useEffect(() => {

    const isAuthenticated = localStorage.getItem("isAuthenticated")
    if(!isAuthenticated) setUserIsLoading(false)

    FirebaseAuthentication.removeAllListeners().then(() => {
      FirebaseAuthentication.addListener('authStateChange', async(result) => {
        
        if(!result.user){
          setUser(null);
          setUserIsLoading(false);
          setApolloClient(defaultAppolloClient);
          localStorage.removeItem("isAuthenticated")
          return;
        }

        if(result.user){
          const { uid, displayName, email, phoneNumber } = result.user;
          localStorage.setItem("isAuthenticated", true);

          const headerLink = setContext(async(_, { headers }) => {
            
            const idToken = await FirebaseAuthentication.getIdToken();
            
            return {
              headers: {
                ...headers,
                autherization: `Bearer ${idToken.token}`,
              }
            }
          });
          
          //////WITH USER
          const _apolloClient = new ApolloClient({
            link:from([ headerLink, link ]),
            cache
          });
          setApolloClient(_apolloClient);
          setUser({ uid, displayName, email, phoneNumber });
          setUserIsLoading(false);
        }
      }).catch((error)=>{
        console.error(error);
      });
    })
    
  }, []); //eslint-disable-line

  return (
    <AuthContext.Provider value={{ user, updateUser, userIsLoading  }}>
      <ApolloProvider client={apolloClient}>
          {children}
      </ApolloProvider>
    </AuthContext.Provider>
  );
};
