import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { Button } from "antd"
import { useIntl } from "react-intl"

export default function CodeForm({ handleSubmitCode, handleBackToLoginForm ,loading, email }) {
  
  const [code, setCode] = useState(null)
  
  const intl = useIntl()

  useEffect(() => {
    if (!code) return;
    if (code.length === 6) {
      handleSubmitCode(code);
    }
  }, [code]); // eslint-disable-line

  const handleChangeOtp = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value )) {
      setCode(e.target.value);
    }
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection:"column",
        alighItem: 'center'
      }}>
        <div style={{ width: '100%',
        display: 'flex',
        justifyItems: 'center',
        flexDirection:"column",
        alignItems:"center"

        }}>
          {/* {inputs} */}
          <div>
          <h1>Verification Code</h1>
          <p>{"Enter your code we sent via email :"} {email}</p>
          {/* <h1>{intl.formatMessage({id:"app.auth.verification.opt"})}</h1> */}
          {/* <p>{intl.formatMessage({id:"app.auth.verification.descptions"})} {email}</p> */}
          </div>
          <TextField
            type="tel"
            // key={index}
            id="outlined-basic"
            value={code}
            variant="outlined"
            inputProps={{ maxLength: 6 ,style:{textAlign: 'center' , fontWeight:"bold", fontSize:"24px" , letterSpacing:"6px"} }}
            style={{ width: '60%'}}
            onChange={handleChangeOtp}
          />

        </div>
        <div style={{ width:"100%" , display:"flex" , justifyContent:"center"}}>
          <Button
            loading={loading}
            id="recaptcha-container"
            className="sign-in-button"
            type="primary"
            htmlType="submit"
            size="large"
            onClick={handleBackToLoginForm}
            style={{
              margin:"6%",
              borderRadius: 2,
              width: '44%'
            }}
            >
            {intl.formatMessage({id:"app.back"})}
          </Button>
        
        </div>
    </div>
  );
}
