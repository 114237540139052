import React, { useEffect, useState } from 'react'
import { Icon, Button , Modal } from 'antd';
import { TextField} from '@material-ui/core';
import useAuth from '../../../Hook/useAuth'
import { compose } from 'recompose';
import { withApollo } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { FormattedMessage, useIntl } from 'react-intl';
import config from '../../../config';
import platformObj from '~/config'
import gql from 'graphql-tag'

// eslint-disable-next-line
const user = gql` 
  query currentUser {
    currentUser {
      _id
      email
      profile {
        _id
        imageUrl
        firstName
        lastName
        birthDate
        gender
        nationality
      }
    }
  }
`


const RegisterationPage = function (props) {

    const intl = useIntl()
    const { history, client} = props // eslint-disable-line
    const [password , setPassword] = useState(null)
    const [passwordError , setPasswordError] = useState(null)
    const [showPassword, setShowPassword ] = useState(false)
    const {signInWithEmail} = useAuth()
    const {token} =  props.match.params
    const [path , setPath] = useState(null)
    const [userEmail, setUserEmail] = useState(null)
    const [ loading , setLoading ] = useState(false)
    const isNative = Capacitor.isNativePlatform()
    // console.log(props);

    useEffect(()=>{

        if(token){
            // console.log(token);
            // const jsonToken = JSON.parse(atob(token.split("&")[0])) 
            // console.log(jsonToken);
            let email , navigatePath ;
            if(isNative){
                // let jsonToken
                // if(token.includes("&")) jsonToken = JSON.parse(atob(token.split("&")[0])) 
                // if(token.includes("%")) jsonToken = JSON.parse(atob(token.split("%")[0])) 
            const jsonToken = JSON.parse(atob(token.split(/[%&]+/)[0]))
            // console.log(jsonToken);
            email = jsonToken.email 
            navigatePath = jsonToken.navigatePath
            }else {
            email = JSON.parse(atob(token)).email
            navigatePath = JSON.parse(atob(token)).navigatePath          
            }
            setUserEmail(email)
            setPath(navigatePath ? navigatePath:"/")
        }

    },[]) // eslint-disable-line

    useEffect(()=>{
        if(password){
            if(password.length >= 8) {
                setPasswordError(false)
            }
            else{
                setPasswordError(true)
            }
        }
    },[password])


    const handleSubmit = async()=>{
        try {
            setLoading(true)
            const res =  await signInWithEmail(userEmail ,  window.location.href , password , "signup")
            // console.log(res);
            if(!res.success){
                setLoading(false)
                return handleError(res.error)
            }
            if(res.success){

                if(isNative) {
                    history.push("/")
                   
                }
                
                setLoading(false)    
                history.push(path);
                window.location.reload()
                
            }
        }catch(error){
            setLoading(false)
            handleError(error)
        }
    }

    function handleError(error) {
        console.error(error);
        Modal.error({
          title: intl.formatMessage({ id: 'app.auth.loginFail' }),
          content: error,
          onOk() {history.push(path)},
        });
      }
  return (
    <div
      style={{
        width: 380,
        animationDuration: `${1.2}s`,
        animationDelay: `${0}s`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop:"16%",
        padding: 20,
        
      }}>
         <div className="loginModalHeader" style={{ width: '100%' }}>
         {process.env.REACT_APP_PLATFORM !== 'maybank' &&
            <div style={{display:"flex" , alignItems:'center' , justifyContent:"space-around"}}>
                <img
                    src="/logorunner.png"
                    style={{ width: '100%', maxWidth: '90px', marginBottom: 20 }}
                    alt="logo"
                />
                <div style={{display:"flex"  ,flexDirection:"column", alignItems:"start" }}>
                    <h1 style={{ fontWeight: '500' , fontSize:20, lineHeight:2 }} id="recaptcha-container">
                    <FormattedMessage id={"app.auth.signined"}/> 
                    </h1>
                    <h1 style={{ fontWeight: 'bold' , fontSize:48 ,lineHeight:0 }}>{config.brandName}</h1>
                </div>
            </div>
        }
        {process.env.REACT_APP_PLATFORM === 'maybank' &&
          <img
          src={platformObj.logo.urlLogin}
          style={{ width: '100%', maxWidth: '300px', marginBottom: 20,  }}
          alt="logo"
        />
        }
        </div>
        <div style={{ width: '100%' }}>

            <div>
            <p style={{ fontWeight: 'semiBold' , fontSize: 20,lineHeight:0 }}>Please set your password</p>
                <div style={{position:"relative",marginTop:"4%" }}>
                    <TextField  label={intl.formatMessage({ id: "app.password"})} variant="outlined" type={showPassword?"text": "password"}  style={{width:"100%" }} onChange={(e)=>setPassword(e.target.value)}   />
                    <div style={{ position:"absolute", top: 0,bottom:0 , right:2, display:"flex", alignItems:"center" , marginRight:"2%"}}>
                        {password && passwordError &&<Icon type="close-circle" style={{fontSize:24 , color:"red" ,marginRight:8}}/>}
                        {password && !passwordError && <Icon type="check-circle"style={{fontSize:24 , color:"green",marginRight:8}} />}
                        {showPassword ?<Icon type="eye" style={{fontSize:24}} onClick={()=> setShowPassword(false)}/>:<Icon type="eye-invisible" style={{fontSize:24}} onClick={()=> setShowPassword(true)}/>}

                    </div>
                </div>
                {passwordError&& <div><FormattedMessage id="app.auth.passwordAtleast"/></div>}
                
            </div>
        

            <div className="loginTextField" style={{ textAlign: 'center' }}>
                    <Button
                    loading= { loading }
                    className="sign-in-button"
                    type="primary"
                    htmlType="submit"
                    size="large"
                    onClick={handleSubmit}
                    style={{
                        padding: '0 30px',
                        borderRadius: 2,
                        width: '100%'
                    }}
                    disabled={!password || passwordError}
                    >
                    <FormattedMessage id="app.auth.submit"/>
                    </Button>
                </div>
        </div>
        {/* { process.env.REACT_APP_PLATFORM === 'maybank' &&
        <div className="links" style={{display:"flex" ,flexDirection:"column", justifyItems:"start", position: 'fixed',width: '100', bottom: '0'}}>
          <h1 style={{ fontWeight: '400' , fontSize:12, lineHeight:2}}>
            <a className="outsideLink" style={{ color : 'black'}} href='https://www.thai.run/'>powered by ThaiRun</a>
          </h1>
        </div>
        } */}
    </div>
  )
}

export default compose(
    withApollo,
    withRouter
)(RegisterationPage)