import React from 'react'
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'
import { ConfigProvider } from 'antd'
import en from 'antd/lib/locale-provider/en_US'
import th from 'antd/lib/locale-provider/th_TH'
import zh from 'antd/lib/locale-provider/zh_CN'
import id from 'antd/lib/locale-provider/id_ID'
import vi from 'antd/lib/locale-provider/vi_VN'
import moment from 'moment'
import 'moment/locale/th'
import 'moment/locale/zh-cn'
import 'moment/locale/id'
import 'moment/locale/vi'
const locales = {
  en,
  th,
  zh,
  id,
  vi
}
const momentLocales = {
  en: 'en',
  th: 'th',
  zh: 'zh-cn',
  id: 'id',
  vi: 'vi'
}
// console.log({ locales })
const ConnectedLocaleProvider = ({ children, i18n: { locale } }) => ((
  moment.locale(momentLocales[locale]),
  <ConfigProvider locale={locales[locale]}>
    {children}
  </ConfigProvider>
))

export default compose(
  inject('i18n'),
  observer
)(ConnectedLocaleProvider)
