import React, { useEffect } from 'react'
import useAuth from '../../../Hook/useAuth'
import { compose } from 'recompose';
import { withApollo } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import config from '../../../config';
import platformObj from '~/config'
import { Capacitor } from '@capacitor/core';

const SignInLinkPage= function (props) {
  
    const {history} = props
    const {token} =  props.match.params
    const {signInWithEmail} = useAuth()
    const isNative = Capacitor.isNativePlatform()
    useEffect(()=>{
      

      if(token){
        console.log(token);
        const jsonToken = JSON.parse(atob(token.split("&")[0])) 
        let email , navigatePath ;
        if(isNative){
         email = jsonToken.email 
         navigatePath = jsonToken.navigatePath
        }else {
          email = JSON.parse(atob(token)).email
          navigatePath = JSON.parse(atob(token)).navigatePath          
        }
        
        const signin = async function(){
            const res = await signInWithEmail(email,window.location.href)
            if(res.success)
            history.push(navigatePath? navigatePath :"/")
        } 
        signin();
      }
    
    },[]) // eslint-disable-line

  return (
    <div
      style={{
        width: 380,
        animationDuration: `${1.2}s`,
        animationDelay: `${0}s`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop:"16%",
        
        padding: 20,
      
      }}>
      
         <div className="loginModalHeader" style={{ width: '100%' }}>
         {process.env.REACT_APP_PLATFORM !== 'maybank' &&
            <div style={{display:"flex" , alignItems:'center' , justifyContent:"space-around"}}>
                <img
                    src="/logorunner.png"
                    style={{ width: '100%', maxWidth: '90px', marginBottom: 20 }}
                    alt="logo"
                />
                <div style={{display:"flex"  ,flexDirection:"column", alignItems:"start" }}>
                    <h1 style={{ fontWeight: '500' , fontSize:20, lineHeight:2 }} id="recaptcha-container">
                    <FormattedMessage id={"app.auth.signined"}/> 
                    
                    </h1>
                    <h1 style={{ fontWeight: 'bold' , fontSize:48 ,lineHeight:0 }}>{config.brandName}</h1>
                </div>
            </div>
          }
          {process.env.REACT_APP_PLATFORM === 'maybank' &&
          <img
          src={platformObj.logo.urlLogin}
          style={{ width: '100%', maxWidth: '300px', marginBottom: 20,  }}
          alt="logo"
        />
        }
        </div>
        {/* { process.env.REACT_APP_PLATFORM === 'maybank' &&
          <div className="links" style={{display:"flex" ,flexDirection:"column", justifyItems:"start", position: 'fixed',width: '100', bottom: '0'}}>
            <h1 style={{ fontWeight: '400' , fontSize:12, lineHeight:2}}>
              <a className="outsideLink" style={{ color : 'black'}} href='https://www.thai.run/'>powered by ThaiRun</a>
            </h1>
          </div>
        } */}
    </div>
  )
}

export default compose(
    withApollo,
    withRouter,
    
)(SignInLinkPage)

