import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { Button } from "antd"
import { useIntl } from "react-intl"

export default function OtpForm({ handleSubmitOtp, handleBackToLoginForm ,loading, number }) {
  // const [otp, setOtp] = useState([]);
  const [otp, setOtp] = useState(null)
  // const inputRefs = useRef([]);
  // const inputs = [];
  const intl = useIntl()

  useEffect(() => {
    if (!otp) return;
    if (otp.length === 6) {
      // // const nullValue = otp.find(el=> !el)
      // if(nullValue >=0){
      //   return
      // }
      // const value = otp.toString().split(",").join("")
      
      handleSubmitOtp(otp);
      
    }
  }, [otp]); // eslint-disable-line


  // useEffect(()=>{
  //   console.log(otp);
  //   const regex = /^[0-9\b]+$/; 
  //   if (!regex.test(otp)) {
  //     console.log("only number");
  //   }
  // },[otp])

  const handleChangeOtp = (e) => {
    const regex = /^[0-9\b]+$/;
    
    if (e.target.value === "" || regex.test(e.target.value )) {
      setOtp(e.target.value);
      // setInitialPhoneNumber(e.target.value)
    }
  };

  // for (let index = 0; index < 6; index++) {
  //   inputs.push(
  //     <TextField
  //       type="tel"
  //       key={index}
  //       id="outlined-basic"
  //       variant="outlined"
  //       inputProps={{ maxLength: 1 }}
  //       style={{ width: '12%', textAlign: 'center' }}
  //       onKeyUp={(e) => {
  //         if(e.key==="Delete" || e.key==="Backspace"){
  //           const value = [...otp]
  //           value[index] = null
  //           setOtp(value)
  //           index <= 5 && inputRefs.current[index - 1].focus();
  //         }else if(e.key==="ArrowLeft"){
  //           index >0 && inputRefs.current[index - 1].focus();
  //         } else if(e.key ==="ArrowRight"){
  //           index < 5 && inputRefs.current[index + 1].focus();}
  //         else{
  //           const value = [...otp]
  //           value[index] = e.target.value
  //           setOtp(value)
  //         index < 5 && inputRefs.current[index + 1].focus();
  //         }

  //         //   if (index < 4) {
  //         //     inputRefs.current[index + 1].focus();
  //         //   } else {
  //         //     handleSubmitOtp();
  //         //   }
  //       }}
  //       autoFocus={index === 0 ? true : false}
  //       inputRef={(ref) => (inputRefs.current[index] = ref)}
  //     />
  //   );
  // }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection:"column",
        alighItem: 'center'
      }}>
        <div style={{ width: '100%',
        display: 'flex',
        justifyItems: 'center',
        flexDirection:"column",
        alignItems:"center"

        }}>
          {/* {inputs} */}
          <div>
          <h1>{intl.formatMessage({id:"app.auth.verification.opt"})}</h1>
          <p>{intl.formatMessage({id:"app.auth.verification.descptions"})} {number}</p>
          </div>
          <TextField
            type="tel"
            // key={index}
            id="outlined-basic"
            value={otp}
            variant="outlined"
            inputProps={{ maxLength: 6 }}
            style={{ width: '50%', textAlign: 'center' }}
            onChange={handleChangeOtp}
            // onKeyUp={(e) => {
            //   if(e.key==="Delete" || e.key==="Backspace"){
            //     const value = [...otp]
            //     value[index] = null
            //     setOtp(value)
            //     index <= 5 && inputRefs.current[index - 1].focus();
            //   }else if(e.key==="ArrowLeft"){
            //     index >0 && inputRefs.current[index - 1].focus();
            //   } else if(e.key ==="ArrowRight"){
            //     index < 5 && inputRefs.current[index + 1].focus();}
            //   else{
            //     const value = [...otp]
            //     value[index] = e.target.value
            //     setOtp(value)
            //   index < 5 && inputRefs.current[index + 1].focus();
            //   }

              //   if (index < 4) {
              //     inputRefs.current[index + 1].focus();
              //   } else {
              //     handleSubmitOtp();
              //   }
            // }}
            // autoFocus={index === 0 ? true : false}
            // inputRef={(ref) => (inputRefs.current[index] = ref)}
          />

        </div>
        <div style={{ width:"100%" , display:"flex" , justifyContent:"center"}}>
          <Button
            loading={loading}
            id="recaptcha-container"
            className="sign-in-button"
            type="primary"
            htmlType="submit"
            size="large"
            onClick={handleBackToLoginForm}
            style={{
              margin:"6%",
              borderRadius: 2,
              width: '44%'
            }}
            >
            {intl.formatMessage({id:"app.back"})}
          </Button>
        
        </div>
    </div>
  );
}
