import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
// Your web app's Firebase configuration

// ///////Dev-Thairun-auth
// const firebaseConfigDev = {
//   apiKey: 'AIzaSyDdyTSwbyZaIJFwTOIZ8e39wT3ep3znqBU',
//   authDomain: 'thairun-auth-dev.firebaseapp.com',
//   databaseURL: 'https://thairun-auth-dev.firebaseio.com',
//   projectId: 'thairun-auth-dev',
//   storageBucket: 'thairun-auth-dev.appspot.com',
//   messagingSenderId: '491615064952',
//   appId: '1:491615064952:web:4ee73c03473080c6142e8d'
// };

// ///////Thairun-auth
// const firebaseConfigProduction = {
//   apiKey: 'AIzaSyCB5uA1TXnuvSk9xmp9I7ci-N4bjD2CT-g',
//   authDomain: 'thairun-auth.firebaseapp.com',
//   databaseURL: 'https://thairun-auth.firebaseio.com',
//   projectId: 'thairun-auth',
//   storageBucket: 'thairun-auth.appspot.com',
//   messagingSenderId: '127933093846',
//   appId: '1:127933093846:web:91060c9f25fc396d127677'
// };


const firbaseConfigMap = {
  "thairun-auth-dev": {
    apiKey: 'AIzaSyDdyTSwbyZaIJFwTOIZ8e39wT3ep3znqBU',
    authDomain: 'thairun-auth-dev.firebaseapp.com',
    databaseURL: 'https://thairun-auth-dev.firebaseio.com',
    projectId: 'thairun-auth-dev',
    storageBucket: 'thairun-auth-dev.appspot.com',
    messagingSenderId: '491615064952',
    appId: '1:491615064952:web:4ee73c03473080c6142e8d'
  },
  "thairun-auth": {
    apiKey: 'AIzaSyCB5uA1TXnuvSk9xmp9I7ci-N4bjD2CT-g',
    authDomain: 'thairun-auth.firebaseapp.com',
    databaseURL: 'https://thairun-auth.firebaseio.com',
    projectId: 'thairun-auth',
    storageBucket: 'thairun-auth.appspot.com',
    messagingSenderId: '127933093846',
    appId: '1:127933093846:web:91060c9f25fc396d127677'
  },
  // "thairun-app-staging":{
  //   apiKey: "AIzaSyChNxeSbkniyvWdFwi0bz2G9onh-ka4Gvw",
  //   authDomain: "thairun-app-staging.firebaseapp.com",
  //   projectId: "thairun-app-staging",
  //   storageBucket: "thairun-app-staging.appspot.com",
  //   messagingSenderId: "827980340895",
  //   appId: "1:827980340895:web:bd3ce768bd0767134e0caa",
  //   measurementId: "G-KZN85EP1YZ"
  // }
}

// console.log(firbaseConfigMap[process.env.REACT_APP_AUTH_PROJECT_ID].projectId);



// Initialize Firebase
const app = initializeApp(
  firbaseConfigMap[process.env.REACT_APP_AUTH_PROJECT_ID]
);
const firestoreDb = getFirestore(app);
const firebaseAuth = getAuth();

export { firebaseAuth, firestoreDb };
